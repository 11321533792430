import { customElement, bindable } from 'aurelia-framework';
import { ChannelTypes } from '../../../../../../../config/constants';
import { ISiteChannelDetails } from '../channelcard-hoc/channelcard-hoc';

import './channelcard.css';

@customElement('channel-card')
export class ChannelCard {
  @bindable() channel: ISiteChannelDetails;
  @bindable() activeAlarms: number;
  channelTypes = ChannelTypes;
}
