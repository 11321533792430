import { BaseViewModel } from '../../../../common/BaseViewModel';
import { autoinject } from 'aurelia-framework';
import { IChannel, ISiteChannel } from '../../../../../interfaces';
import { Logger } from 'aurelia-logging';
import { IAsyncEntity } from '../../../../../types';
import {
  ensureNumber,
  channelIsTankChannel,
  orderByPredicate
} from '../../../../../utility';
import { ChannelService } from '../../../../../services';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../../config';

import './sitedetailschannelslist.css';

interface ISiteDetailsTanksListState {
  channels: IAsyncEntity<IChannel[]>;
}

@autoinject()
export class SiteDetailsTanks extends BaseViewModel<
  ISiteDetailsTanksListState
> {
  constructor(
    private router: Router,
    private channelService: ChannelService,
    logger: Logger
  ) {
    super(logger);
  }

  activate({ id }: { id: string }) {
    this.attachMapState(this.mapState(ensureNumber(id)));
  }

  mapState = (siteId: number) => (): ISiteDetailsTanksListState => {
    const channelsFetcher = this.channelService.getSiteChannelsForSite(siteId);

    channelsFetcher // Prefetching all channelhistory
      .map(channels => channels.map(c => c.siteChannelId))
      .bind(this.channelService.getChannelHistoryForSiteChannels);

    return {
      channels: channelsFetcher
        .map(channels => orderByPredicate(channels, ch => ch.sortIndex, 'asc'))
        .getAsyncEntity()
    };
  };

  navigateToDetails = (channel: ISiteChannel) => {
    if (channelIsTankChannel(channel))
      this.router.navigateToRoute(routeNames.sitedetailsTanksDetails, {
        siteChannelId: channel.siteChannelId,
        tab: 'graph'
      });
    else
      this.router.navigateToRoute(routeNames.sitedetailsChannelDetails, {
        siteChannelId: channel.siteChannelId,
        tab: 'graph'
      });
  };
}
