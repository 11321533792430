import { customElement, bindable } from 'aurelia-templating';
import { BaseViewModel } from '../../../../../../common';
import {
  ChannelService,
  UnitService,
  ControllerService,
  AlarmService,
  ProductService
} from '../../../../../../../services';
import {
  ITrendData,
  IChannelTrendStats,
  IChannelTrendStatsDetails
} from '../../../../../../../models';
import { IAsyncEntity } from '../../../../../../../types';
import { getLogger } from 'aurelia-logging';
import {
  ISiteChannel,
  ChannelTypes,
  IUnit,
  ITankDetails,
  IAlarm,
  IProduct
} from '../../../../../../../interfaces';
import { autoinject } from 'aurelia-framework';
import {
  isNone,
  formatDate,
  emptyArray,
  getTimezoneByIdOrDefault,
  ensureDate
} from '../../../../../../../utility';
import { TimeUntillValueConverter } from '../../../../../../../value-converters/timeUntillValueConverter';
import { MeasurementFormatValueConverter } from '../../../../../../../value-converters/measurementFormatValueConverter';

import './channelcard-hoc.css';

export interface ISiteChannelDetails extends ISiteChannel {
  hasAlarm: boolean;
  product: IProduct | undefined;
  unit: IUnit | undefined;
  tankDetails?: ITankDetailsDetails;
}

interface ITankDetailsDetails extends ITankDetails {
  minimumDateHasBeenReached: boolean;
}

interface IChannelCardHoc {
  siteChannelId: number;
  channel: IAsyncEntity<ISiteChannelDetails>;
  history: IAsyncEntity<ITrendData>;
  graphType: string | undefined;
  stats: IAsyncEntity<IChannelTrendStatsDetails[]>;
  timezone: string | undefined;
  unit: IUnit | undefined;
  alarms: IAlarm[];
}

const createTankDetailsDetails = (
  tankDetails: ITankDetails
): ITankDetailsDetails => ({
  ...tankDetails,
  minimumDateHasBeenReached: tankDetails.reachMinimum
    ? ensureDate(tankDetails.reachMinimum) < new Date()
    : false
});
const createSiteChannelDetail = (
  channel: ISiteChannel,
  alarms: IAlarm[],
  products: IProduct[],
  units: IUnit[]
): ISiteChannelDetails => ({
  ...channel,
  product: products.find(p => p.productId === channel.productId),
  hasAlarm: !isNone(
    alarms.find(
      alarm => alarm.siteChannelId === channel.siteChannelId && alarm.active
    )
  ),
  unit: units.find(u => u.unitId === channel.unitId),
  tankDetails: isNone(channel.tankDetails)
    ? undefined
    : createTankDetailsDetails(channel.tankDetails)
});

@autoinject()
@customElement('channelcard-hoc')
export class ChannelCardHoc extends BaseViewModel<IChannelCardHoc> {
  @bindable({ changeHandler: 'reAttachmapState' }) siteChannel:
    | ISiteChannel
    | undefined;

  constructor(
    private channelService: ChannelService,
    private unitService: UnitService,
    private productService: ProductService,
    private alarmService: AlarmService,
    private timeUntil: TimeUntillValueConverter,
    private controllerService: ControllerService,
    private measurement: MeasurementFormatValueConverter
  ) {
    super(getLogger('ChannelCard-Hoc'));
  }

  reAttachmapState(
    newValue: ISiteChannel | undefined,
    oldValue: ISiteChannel | undefined
  ) {
    if (isNone(newValue) || newValue === oldValue) return;
    this.attachMapState(this.mapState(newValue));
  }

  calculateChannelStatisticsFromTank = (
    channel: ISiteChannel,
    percentageUnit: IUnit | undefined,
    channelUnit: IUnit | undefined
  ): IChannelTrendStats[] =>
    isNone(channel.tankDetails)
      ? []
      : [
          {
            value: channel.lastSample,
            label: channel.capacity
              ? `${this.measurement.toView(
                  channel.capacity - channel.lastSample,
                  channelUnit,
                  true
                )} avail.`
              : 'Last value',
            unitId: channel.unitId
          },
          {
            value: channel.tankDetails.percentage,
            label: 'Full',
            unitId: percentageUnit ? percentageUnit.unitId : undefined
          },
          {
            value: this.timeUntil.toView(channel.tankDetails.reachMinimum),
            label: 'Until minimum',
            doNotParse: true
          },
          {
            value: formatDate(channel.tankDetails.reachMinimum, false, '.'),
            label: 'Reaches minimum',
            doNotParse: true
          }
        ];

  mapToTrendStatsDetails = (units: IUnit[]) => (stats: IChannelTrendStats[]) =>
    stats.map<IChannelTrendStatsDetails>(stat => ({
      ...stat,
      unit: units.find(u => u.unitId === stat.unitId)
    }));

  getChannelStatistics = (units: IUnit[]) => (channel: ISiteChannel) => {
    const percentageUnit = units.find(unit => unit.symbol === '%');
    const channelUnit = units.find(unit => unit.unitId === channel.unitId);
    switch (channel.channelType) {
      case ChannelTypes.Tank:
        return this.calculateChannelStatisticsFromTank(
          channel,
          percentageUnit,
          channelUnit
        );
    }
    return undefined;
  };

  mapState = (siteChannel: ISiteChannel) => (): IChannelCardHoc => {
    const siteChannelId = siteChannel.siteChannelId;
    const siteId = siteChannel.siteId;
    const unitFetcher = this.unitService.fetchUnits();
    const trendAndStatsFetcher = this.channelService.getChannelHistoryForSiteChannel(
      siteChannelId
    );
    const productFetcher = this.productService.getAll();
    const alarmFetcher = this.alarmService.getAlarmsBySiteId(siteId);

    const controllersFetcher = this.controllerService.getControllersBySiteId(
      siteId
    );

    const timezoneFetcher = controllersFetcher.map(controllers => {
      const controller = controllers.find(
        c => c.controllerId == siteChannel.controllerId
      );
      return getTimezoneByIdOrDefault(controller && controller.timeZoneId);
    });
    const units = unitFetcher.getEntityOrDefault(emptyArray);
    const unitId = siteChannel.unitId;

    return {
      siteChannelId,
      graphType:
        siteChannel.channelType === ChannelTypes.Dose ? 'dose' : 'line',
      history: trendAndStatsFetcher.map(m => m.trendData).getAsyncEntity(),
      stats: trendAndStatsFetcher
        .map(
          trendAndStats =>
            this.getChannelStatistics(units)(siteChannel) ||
            trendAndStats.statistics
        )
        .map(this.mapToTrendStatsDetails(units))
        .getAsyncEntity(),
      channel: alarmFetcher
        .map3(productFetcher, unitFetcher, (alarms, products, units) =>
          createSiteChannelDetail(siteChannel, alarms, products, units)
        )
        .getAsyncEntity(),
      timezone: timezoneFetcher.getEntityOrUndefined(),
      unit: units.find(unit => unitId === unit.unitId),
      alarms: alarmFetcher
        .getEntityOrDefault([])
        .filter(alarm => alarm.siteChannelId === siteChannelId && alarm.active)
    };
  };
}
